import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { version } from '../../../../package.json';
import { environment } from '../environments/environment';
import {
  AppService,
  TrustService,
  StakService,
  AlloService,
  FileService,
  HealthService,
  ContactService,
} from '@softbrik/data/services';
import { translate as t } from '@softbrik/translate';
import { Subscription } from 'rxjs';

// Routes for which we do not perform sandbox check
const SANDBOX_EXCEPTIONS = ['/login', '/trust-feedback', '/stak-reply'];

@Component({
  selector: 'softbrik-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Softbrik';

  @HostBinding('class.with-sidebar') withSidebar: boolean;
  @HostBinding('class.is-plain') plainLayout: boolean;

  constructor(
    public app: AppService,
    public trust: TrustService,
    public stak: StakService,
    public allo: AlloService,
    public health: HealthService,
    public file: FileService,
    public contact: ContactService,
    public router: Router
  ) {
    this.allo.API_LINK = environment.allo;
    this.app.API_LINK = environment.api;
    this.contact.API_LINK = environment.contacts;
    this.file.API_LINK = environment.file;
    this.health.API_LINK = environment.health;
    this.stak.API_LINK = environment.stak;
    this.trust.API_LINK = environment.trust;

    localStorage.setItem('ALLO_API_LINK', this.allo.API_LINK);
    localStorage.setItem('APP_API_LINK', this.app.API_LINK);
    localStorage.setItem('CONTACT_API_LINK', this.contact.API_LINK);
    localStorage.setItem('FILE_API_LINK', this.file.API_LINK);
    localStorage.setItem('HEALTH_API_LINK', this.health.API_LINK);
    localStorage.setItem('STAK_API_LINK', this.stak.API_LINK);
    localStorage.setItem('TRUST_API_LINK', this.trust.API_LINK);

    this.app.version = version;

    if (this.app.isFeedback) {
      this.withSidebar = false;
      this.plainLayout = true;
    } else {
      this.plainLayout = false;
    }

    this.app.user.subscribe((user) => {
      if (user) {
        this.handleMenu();
        this.handleSandbox();
      }
    });
  }

  private sidebarS$: Subscription;

  ngOnInit() {
    this.sidebarS$ = this.app.navigationSidebar.subscribe({
      next: (items) => {
        this.withSidebar = items.length > 0;
      },
    });
  }

  ngOnDestroy() {
    this.sidebarS$?.unsubscribe();
  }

  handleMenu() {
    this.app.setTopNavigation([
      {
        name: t('nav.Home'),
        link: '/',
        linkMethod: 'js',
        slot: 'left',
        type: 'icon',
        data: 'fa fa-home',
      },
      {
        name: t('nav.User menu'),
        slot: 'right',
        type: 'icon',
        linkMethod: 'none',
        data: 'fa fa-user',
        values: [
          {
            name: t('nav.Settings'),
            link: 'settings',
            type: 'text-icon',
            data: 'fa fa-cog',
          },
          {
            type: 'spacer',
            class: 'is-hidden-touch',
          },
          {
            name: t('nav.Log out'),
            type: 'text-icon',
            data: 'fa fa-sign-out',
            event: 'logout',
          },
        ],
      },
      {
        name: t('nav.Help'),
        slot: 'right',
        type: 'icon',
        data: 'fa fa-book',
        event: 'showHelp',
      },
    ]);
  }

  handleSandbox() {
    const path = window.location.pathname;

    if (SANDBOX_EXCEPTIONS.includes(path)) {
      return;
    }

    // Update domain_verified for the components that cannot access the sandbox observable
    // This can be removed when merged with dev, in dev the observable can be used in
    // the html for this component directly on the side menu.
    this.contact.isSandbox().subscribe((isSandbox) => {
      const wasSandbox =
        Number(window.sessionStorage.getItem('domain_verified') || '0') === 0;
      if (isSandbox) {
        window.sessionStorage.removeItem('domain_verified');
      } else {
        window.sessionStorage.setItem('domain_verified', isSandbox ? '0' : '1');
      }

      if (wasSandbox !== isSandbox) {
        window.location.reload();
      }
    });
  }

  toggleHelp() {
    this.app.showHelp = !this.app.showHelp;
  }

  logout() {
    this.app.logout();
  }

  navigateTop(event: CustomEvent) {
    this.app.navigate(event.detail);
  }

  navigateSidebar(event: CustomEvent) {
    this.app.sidebarHandler(event);
  }
}
