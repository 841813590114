import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { IssueCommentComponent } from './issue-comment/issue-comment.component';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { ActionPortalComponent } from './action-portal/action-portal.component';
import { SidebarPortalComponent } from './sidebar-portal/sidebar-portal.component';
import { TimeAgoPipe, TimeRelativePipe } from './pipes';
import { TranslateModule } from '@softbrik/translate';

const components = [
  // Components
  ActionBarComponent,
  ActionPortalComponent,
  IssueCommentComponent,
  SidebarPortalComponent,
  // Pipes
  TimeAgoPipe,
  TimeRelativePipe,
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule],
  declarations: components,
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiCoreModule {}
