import get from 'lodash/get';

const NO_TRANSLATIONS = Object.freeze(
  JSON.parse(window.localStorage.getItem('translations') || '{}')
);

declare global {
  interface Window {
    Softbrik: {
      translations: Object;
    };
  }
}

export const setTranslations = (translations: Object) => {
  window.Softbrik = window.Softbrik || { translations };
  window.Softbrik.translations = translations;
  window.localStorage.setItem('translations', JSON.stringify(translations));
};

export const getTranslations = () => {
  return window.Softbrik?.translations || NO_TRANSLATIONS;
};

export const translate = (key: string): string => {
  const translation = get(getTranslations(), key);
  if (!translation) {
    console.log('Missing translation for key: ', key);
    return key;
  }
  return translation;
};
