export const script = document.createElement('script');

function defaultFn() {
  console.log('default');
}

// stringify function to embed it into html
script.textContent = `
  const defaultFn = ${defaultFn.toString()};
`;
