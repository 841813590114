import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppService } from '@softbrik/data/services';
import { Brik } from '@softbrik/data/models';
import { asyncScheduler, Observable, of, scheduled } from 'rxjs';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeAll, shareReplay, tap } from 'rxjs/operators';

const ADMIN_BRIKS = ['admin', 'contact'];

@Component({
  selector: 'softbrik-page',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  public session = this.app.session.scope('dashboard');
  public briksAll: Observable<Brik[]>;
  public briks: Observable<Brik[]>;
  public briksAdmin: Observable<Brik[]>;
  public API_LINK: string = environment.api;
  public customerName = '';
  public isCached = true;

  constructor(
    public app: AppService,
    public title: Title,
    public translate: TranslateService
  ) {}

  public t(key: string) {
    return this.translate.get(key).toPromise();
  }

  ngOnInit() {
    this.setCustomer();
    this.loadBriks();
    this.app.clearSidebar();
  }

  loadBriks() {
    this.app.is_loading = false;
    this.app.currentBrik = 'dashboard';
    this.briksAll = scheduled(
      [this.cachedBriks(), this.app.getUserBriks(this.API_LINK)],
      asyncScheduler
    ).pipe(
      mergeAll(),
      tap({
        next: (briks) => {
          this.session.setItem('briks', briks);
        },
      }),
      shareReplay()
    );

    this.briks = this.briksAll.pipe(
      map((briks) => {
        return briks.filter((brik) => !ADMIN_BRIKS.includes(brik.id));
      })
    );
    this.briksAdmin = this.briksAll.pipe(
      map((briks) => briks.filter((brik) => ADMIN_BRIKS.includes(brik.id)))
    );
  }

  private cachedBriks() {
    return of(this.session.getItem('briks') || []);
  }

  setCustomer() {
    const { customer_alias } = this.app.decodeToken();
    this.customerName =
      customer_alias[0].toUpperCase() + customer_alias.substring(1);
  }

  async openBrik(brikId: string) {
    this.app.currentBrik = brikId;
    this.setTitle(`Softbrik - ${await this.t(`briks.${brikId}`)}`);
    this.app.store.setItem('brik', brikId);
    if (['trust', 'stak'].includes(brikId)) {
      this.app.navigate(brikId);
    } else {
      this.app.navigate(brikId + '/dashboard');
    }
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}
