import { TokenInterceptor } from './interceptors/token.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  isDevMode,
  APP_INITIALIZER,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataAppDataServiceModule } from '@softbrik/data/services';
import { LoginComponent } from './login/login.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorInterceptor, AuthGuard } from './interceptors';
import { HelpComponent } from './help/help.component';
import { FilterHelpPipe } from './filter-help-pipe/filter-help.pipe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SettingsComponent } from './settings/settings.component';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { ChangeLanguageComponent } from './settings/change-language/change-language.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { UiCoreModule } from '@softbrik/ui/core';
import { clearKeys } from '@softbrik/shared/helpers';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import {
  createBusinessAwareLoader,
  BusinessProvider,
  initTranslation,
} from '@softbrik/translate';

Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  onError: (event) => {
    // Skip reporting when in dev mode
    if (isDevMode()) return false;
    // If the error was unhandled clear keys
    if (event.unhandled) {
      clearKeys(['app_brik']);
    }
  },
});

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-language',
    component: ChangeLanguageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'admin',
    loadChildren: () =>
      import(`@softbrik/briks/admin`).then((m) => m.BriksAdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'allo',
    loadChildren: () =>
      import(`@softbrik/briks/allo`).then((m) => m.BriksAlloModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contact',
    loadChildren: () =>
      import(`@softbrik/briks/contact`).then((m) => m.BriksContactModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'file',
    loadChildren: () =>
      import(`@softbrik/briks/file`).then((m) => m.BriksFileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'health',
    loadChildren: () =>
      import(`@softbrik/briks/health`).then((m) => m.BriksHealthModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stak',
    loadChildren: () =>
      import(`@softbrik/briks/stak`).then((m) => m.BriksStakModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'trust',
    loadChildren: () =>
      import(`@softbrik/briks/trust`).then((m) => m.BriksTrustModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'future-react',
    loadChildren: () =>
      import('@softbrik/briks/future').then((m) => m.BriksFutureModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'trust-feedback',
    loadChildren: () =>
      import('@softbrik/public-form').then((m) => m.PublicFormModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stak-reply',
    loadChildren: () =>
      import('@softbrik/stak-reply').then((m) => m.StakReplyModule),
    canActivate: [AuthGuard],
  },
];

export function appInitializerFactory(
  translateService: TranslateService
): () => Promise<any> {
  return () => initTranslation(translateService).pipe(take(1)).toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HelpComponent,
    FilterHelpPipe,
    ForgotPasswordComponent,
    SettingsComponent,
    ChangePasswordComponent,
    ChangeLanguageComponent,
    MessageDialogComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
    FormsModule,
    ReactiveFormsModule,
    DataAppDataServiceModule,
    UiCoreModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      useDefaultLang: true,
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: createBusinessAwareLoader,
        deps: [HttpClient, BusinessProvider],
      },
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    { provide: BusinessProvider, useClass: BusinessProvider },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
})
export class AppModule {}
