import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import {
  TrustItem,
  TrustItemFeedback,
  PaginatedResponse,
  EmailList,
  EmailListRaw,
  PaginationRequest,
  TrustItemType,
  TrustItemDesign,
} from '@softbrik/data/models';
import { DOCUMENT } from '@angular/common';
import { createParams } from './utils';
import { map } from 'rxjs/operators';
import {
  StorageKeyHandler,
  createKeyHandler,
  StorageType,
} from '@softbrik/shared/helpers';

export const YALLO_DROPDOWN = [
  'iPhone',
  'Android Galaxy Phone',
  'Android Other Phone',
  'Strong Box',
  'Mi Box',
  'Desktop Chrome',
  'Desktop Firefox',
  'Desktop Safari',
  'Desktop Other Browser',
  'Mobile Chrome',
  'Mobile Firefox',
  'Mobile Safari',
  'Mobile Other Browser',
  'Other Device',
];

@Injectable({
  providedIn: 'root',
})
export class TrustService {
  // public API_LINK = `https://rc6g6rtra7.execute-api.eu-west-1.amazonaws.com/prod`;
  public API_LINK: string = '';

  public mailingList;

  public store: StorageKeyHandler = createKeyHandler(
    'trust',
    StorageType.LOCAL
  );
  public session: StorageKeyHandler = createKeyHandler(
    'trust',
    StorageType.SESSION
  );

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) public document: Document
  ) {
    if (!this.API_LINK) {
      this.API_LINK = localStorage.getItem('TRUST_API_LINK');
    }
  }

  getEmailList(id: number) {
    return this.getEmailLists({
      count: 1,
      filter: JSON.stringify({ id }),
    }).pipe(
      map((response) => {
        return response.data.pop();
      })
    );
  }

  getEmailLists(
    params: PaginationRequest
  ): Observable<PaginatedResponse<EmailList>> {
    if (!params.filter) {
      params.filter = JSON.stringify({ is_active: 1 });
    }
    const link = localStorage.getItem('CONTACT_API_LINK');
    const query = createParams(params);

    return this.http
      .get<PaginatedResponse<EmailListRaw>>(`${link}/email-lists?${query}`)
      .pipe(
        map((response) => {
          return {
            ...response,
            data: response.data.map((list) => ({
              id: list.id,
              email: list.name,
              name: list.description,
              is_active: list.is_active,
              is_verified: list.is_verified,
            })),
          };
        })
      );
  }

  /**
   * @todo move to common
   */
  createEmailList(list: EmailList): Observable<EmailList> {
    const link = localStorage.getItem('CONTACT_API_LINK');
    return this.http
      .post<EmailListRaw>(`${link}/email-lists`, {
        name: list.email,
        description: list.name,
        is_active: list.is_active,
        is_verified: list.is_verified,
      })
      .pipe(
        map((list) => ({
          id: list.id,
          email: list.name,
          name: list.description,
          is_active: list.is_active,
          is_verified: list.is_verified,
        }))
      );
  }

  /**
   * @todo move to common
   */
  updateEmailList(list: EmailList): Observable<EmailList> {
    const link = localStorage.getItem('CONTACT_API_LINK');
    return this.http
      .put<EmailListRaw>(`${link}/email-lists/${list.id}`, {
        id: list.id,
        name: list.email,
        description: list.name,
        is_active: list.is_active,
        is_verified: list.is_verified,
      })
      .pipe(
        map((list) => ({
          id: list.id,
          email: list.name,
          name: list.description,
          is_active: list.is_active,
          is_verified: list.is_verified,
        }))
      );
  }

  /**
   * @todo move to common
   */
  deleteEmailList(list: EmailList) {
    const link = localStorage.getItem('CONTACT_API_LINK');
    return this.http.delete<EmailList>(`${link}/email-lists/${list.id}`);
  }

  getDesigns(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<TrustItemDesign>>(
      `${this.API_LINK}/feedback-types-designs?${query}`
    );
  }

  getItem(id: number) {
    return this.http.get<TrustItem>(`${this.API_LINK}/items/${id}`);
  }

  getItems(params: PaginationRequest) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<TrustItem>>(
      `${this.API_LINK}/items?${query}`
    );
  }

  createItem(item: TrustItem) {
    return this.http.post(`${this.API_LINK}/items`, item);
  }

  updateItem(item: TrustItem) {
    return this.http.put(`${this.API_LINK}/items/${item.id}`, item);
  }

  updateItemFeedback(feedback: Partial<TrustItemFeedback>) {
    return this.http.put(`${this.API_LINK}/feedback/${feedback.id}`, feedback);
  }

  updateFeedbackLanguage(item: TrustItem, feedback_language_id: number) {
    const { id: item_id } = item;
    return this.http.post(`${this.API_LINK}/items/translate-feedback/`, {
      feedback_language_id,
      item_id,
    });
  }

  createItemQR(id: number, text: string) {
    return this.http.post(`${this.API_LINK}/qr`, { id, text });
  }

  getItemFeedback({
    itemId,
    ...params
  }: PaginationRequest & { itemId: number }) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<TrustItemFeedback>>(
      `${this.API_LINK}/items/${itemId}/feedbacks?${query}`
    );
  }

  markFeedbackAsRead(feedbackId: number) {
    return this.http.patch<unknown>(
      `${this.API_LINK}/feedback/${feedbackId}/mark-as-read`,
      null
    );
  }

  getItemFeedbackById(formId: number, feedbackId: number) {
    return this.getItemFeedback({
      itemId: formId,
      filter: JSON.stringify({
        id: feedbackId,
      }),
    }).pipe(
      map((result) => {
        return result.data.pop();
      })
    );
  }

  getTagsForItem(itemId: number) {
    const params = createParams({
      count: 999,
    });
    return this.http.get<PaginatedResponse<string>>(
      `${this.API_LINK}/items/${itemId}/tags?${params}`
    );
  }

  createFeedbackTypeItem(
    itemid,
    name,
    description,
    type,
    isshow,
    order_no,
    is_mandatory
  ) {
    return this.http.post(`${this.API_LINK}/feedback-type-items`, {
      itemid,
      name,
      description,
      type,
      isshow,
      order_no,
      is_mandatory,
    });
  }

  deleteAllFeedbackTypeItems(itemid) {
    return this.http.delete(`${this.API_LINK}/feedback-type-items/${itemid}`);
  }

  getAllFeedbackTypeItems({
    itemId,
    ...params
  }: PaginationRequest & { itemId: number }) {
    const subdomain =
      this.document.location.hostname.includes('ngrok') ||
      this.document.location.hostname === 'localhost'
        ? 'softdrinks'
        : this.document.location.hostname.split('.')[0];
    const query = createParams(params, { id: itemId, subdomain });
    return this.http.get<PaginatedResponse<TrustItemType>>(
      `${this.API_LINK}/feedback-type-items?${query}`
    );
  }

  getDesignTemplate({
    designId,
    ...params
  }: PaginationRequest & { designId: number }) {
    const query = createParams(params, { id: designId });
    return this.http.get<PaginatedResponse<TrustItemType>>(
      `${this.API_LINK}/feedback-type-templates?${query}`
    );
  }

  createFeedback(feedback) {
    return this.http.post(`${this.API_LINK}/feedback`, feedback);
  }

  getSignedUrl(type: string) {
    return this.http.get<{ uploadUrl: string; fileName: string }>(
      `${this.API_LINK}/multi-reply-audio-url?content=${encodeURIComponent(
        type
      )}`
    );
  }

  transcribeAudioFeedback(fileName, item_id) {
    return this.http.post(`${this.API_LINK}/transcribe-audio-feedback`, {
      fileName,
      item_id,
    });
  }

  uploadAudioFeedback(link: string, feedback: Blob) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': feedback.type,
      }),
    };
    return this.http.put(link, feedback, options);
  }

  getFile(id, isDownload) {
    return this.http.post(`${this.API_LINK}/get-file`, { id, isDownload });
  }

  multiReply(
    subject: string,
    text: string,
    from: string,
    copyto: string,
    recipients: string[],
    language_id: number,
    attachment?: string
  ) {
    return this.http.post(`${this.API_LINK}/multi-reply`, {
      subject,
      text,
      from,
      copyto,
      bcc: recipients.join(','),
      language_id,
      attachment,
    });
  }

  createStakIssue(issue) {
    const link = localStorage.getItem('STAK_API_LINK');
    return this.http.post(`${link}/trust-issues`, issue);
  }
}
