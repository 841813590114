import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  FormBuilder,
} from '@angular/forms';
import { isValidEmail } from '@softbrik/shared/helpers';
import { AppService } from '@softbrik/data/services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

function emailValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) return null;

    return isValidEmail(control.value) ? null : { email: true };
  };
}

@Component({
  selector: 'softbrik-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    email: ['', [Validators.required, emailValidator()]],
    password: ['', Validators.required],
    customer_alias: ['', Validators.required],
    version: ['v2'],
  });

  public apiError: string;

  constructor(
    public app: AppService,
    public fb: FormBuilder,
    public translate: TranslateService,
    public route: ActivatedRoute
  ) {
    this.app.clearSidebar();
  }

  ngOnInit(): void {
    this.form.patchValue({ customer_alias: this.app.customerAlias });
  }

  get f() {
    return this.form.controls;
  }

  public t(key: string, props?: object) {
    return this.translate.get(key, props).toPromise();
  }

  login() {
    this.apiError = '';

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.notifyInvalid();
      return;
    }

    this.app.is_loading = true;

    this.app.login(this.form.value).subscribe(
      () => {
        this.app.is_loading = false;
        if (this.route.snapshot.queryParams.returnUrl) {
          document.location = this.route.snapshot.queryParams.returnUrl;
        } else {
          this.app.navigate('dashboard');
        }
      },
      (error: string) => {
        this.app.is_loading = false;
        this.t(`auth.errors.${error}`).then((value) => {
          this.app.notify(
            this.t('common.Error'),
            this.t('auth.Login failed {reason}', { reason: value }),
            'error'
          );
          this.apiError = value;
        });
      }
    );
  }

  private notifyInvalid() {
    this.app.notify(
      this.t('component.feedback.Missing or invalid'),
      this.t('component.feedback.Please fill out the required form fields.'),
      'error'
    );
  }
}
