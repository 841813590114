import { appendStyles } from '@softbrik/shared/helpers';
import { MenuItem } from '@softbrik/data/services';
import { translate as t } from '@softbrik/translate';

appendStyles(`
  .menu {
    height: 100%;
    padding: 1.5rem;
    box-shadow: inset -5px -2px 10px rgba(var(--color-black-rgb), 0.15);
    color: var(--color-white);
    background: linear-gradient(to bottom,var(--color-blue-light),var(--color-blue));
  }

  .menu__slide {
    position: sticky;
    top: calc(var(--h-top-menu) + 1rem);
  }

  .menu :is(.menu-label, .menu-list) {
    margin-left: 1rem;
  }

  .menu-label {
    color: var(--color-white);
    font-weight: bold;
    font-size: 1rem;
  }

  .menu-label:not(:last-child) {
    margin-top: 2rem;
  }

  .menu-list > li > a {
    color: var(--color-white);
  }

  .menu-list > li > a:hover {
    color: var(--color-white);
    background-color: var(--color-blue);
    transition: background-color linear 300ms;
    border-radius: 4px;
  }

  .brik-logo {
    margin-bottom: 2rem;
    font-size: 30px;
    font-weight: 800;
    color: var(--color-white);
  }

  .brik-logo > img {
    border-radius: 4px;
    height: 30px;
  }

  .email-notice {
    margin-top: 1rem;
    animation: fade-in 2s ease-out, from-below 2s ease-out;
    box-shadow: 4px 5px 6px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 1023px){
    .menu {
      height: 100%;
    }
  }
`);

export class BrikSideMenu extends HTMLElement {
  public static observedAttributes = ['items'];

  static get boundAttributes() {
    return ['items'];
  }

  get items() {
    return this.hasAttribute('items');
  }

  set items(input) {
    this.setAttribute('items', input.toString());
  }

  connectedCallback() {
    let brikId = JSON.parse(localStorage.getItem('app_brik')).value;
    brikId = brikId.replace(brikId.charAt(0), brikId.charAt(0).toUpperCase());

    setTimeout(() => {
      const bridId$ = document.getElementById('brikId');
      if (bridId$) {
        bridId$.innerHTML = t(`briks.${brikId} brik`);
      }
      const images = Array.from(document.getElementsByClassName('brikLogo'));
      images.forEach((image: HTMLImageElement) => {
        image.src = `assets/brik-icons/${brikId.toLowerCase()}.svg`;
      });
    }, 0);
  }

  attributeChangedCallback(_name: string, _oldValue: never, newValue: string) {
    const items = JSON.parse(newValue) as MenuItem[];

    let menuHtml = ``;

    items.forEach((item) => {
      menuHtml += `
        <div class="menu-label">${item.name}</div>
        <ul class="menu-list">
          ${item.values
            .map(
              (sub) =>
                `<li data-event="navigate" data-event-value="${sub.link}"><a>${sub.name}</a></li>`
            )
            .join('')}
        </ul>`;
    });

    const notice = this.renderNotice();

    this.innerHTML = `
      <div class="menu on-mobile" style="position: relative">
        <div class="menu__slide">
          <h4 class="brik-logo">
            <img class="brikLogo" src="assets/brik-icons/admin.svg"> <span id="brikId"> </span>
          </h4>

          ${menuHtml}

          <div id="sidebar-portal"></div>

          ${notice}
        </div>
      </div>
    `;

    // Emit events
    this.querySelectorAll('[data-event]').forEach((item) => {
      const eventName = item.getAttribute('data-event');
      const eventValue = item.getAttribute('data-event-value');
      if (!eventName) return;

      item.addEventListener('click', (event: Event) => {
        event.preventDefault();
        const el = event.target as HTMLElement;
        el.dispatchEvent(
          new CustomEvent(eventName, {
            bubbles: true,
            detail: eventValue,
          })
        );
      });
    });
  }

  renderNotice() {
    if (window.sessionStorage.getItem('domain_verified')) return '';

    return `<div class="email-notice message is-warning">
      <div class="message-body content">
        <p>
          <span>${t('sidebar.Account verfication pending')}</span>
          <a href="/trust/email-notifications">${t('sidebar.See more')}</a>.
        </p>
      </div>
    </div>`;
  }
}

customElements.define('softbrik-side-menu', BrikSideMenu);
