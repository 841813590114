import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { setTranslations } from './webcomponent-helpers';
import { TranslateLoader } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BusinessProvider {
  getBusiness(): Observable<string> {
    let business = window.localStorage.getItem('business');
    business = ['support', 'health'].includes(business) ? business : 'support';
    return of(business);
  }
}

export class BusinessAwareLoader implements TranslateLoader {
  private readonly business$: Observable<string>;
  private readonly http: HttpClient;

  constructor(http: HttpClient, provider: BusinessProvider) {
    this.http = http;
    this.business$ = provider.getBusiness();
  }

  getTranslation(lang: string) {
    const translations$ = this.business$.pipe(
      flatMap((business: string) =>
        this.http.get<Record<string, unknown>>(
          `/assets/lang/${business}/${lang}.json`
        )
      )
    );
    translations$.subscribe(
      (translations: Record<string, unknown>) => {
        setTranslations(translations);
      },
      (error: Error) => console.log(error)
    );
    return translations$;
  }
}

export function createBusinessAwareLoader(
  http: HttpClient,
  provider: BusinessProvider
) {
  return new BusinessAwareLoader(http, provider);
}
