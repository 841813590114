import { script } from './notification-message-script';

const style = document.createElement('style');
style.textContent = `
  .notification-wrapper{
    width: 360px;
    position: fixed;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 9999;
    opacity: 0;
    animation: fadeOut 4s linear forwards;
  }

  @keyframes fadeOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; transform: translateY(0px);}
    99% { opacity: 0; transform: translateY(-30px);}
    100% { opacity: 0; }
  }
`;

document.body.appendChild(style);
document.body.appendChild(script);

// @dynamic
export class NotificationMessage extends HTMLElement {
  public static observedAttributes = ['name', 'text', 'type'];

  static get boundAttributes() {
    return ['name', 'text', 'type'];
  }

  get name() {
    return this.hasAttribute('name');
  }

  set name(input) {
    this.setAttribute('name', input.toString());
  }

  get text() {
    return this.hasAttribute('text');
  }

  set text(input) {
    this.setAttribute('text', input.toString());
  }

  get type() {
    return this.hasAttribute('type');
  }

  set type(input) {
    this.setAttribute('type', input.toString());
  }

  public title: string;
  public description: string;
  public color: string;

  attributeChangedCallback(name, oldValue, newValue) {
    if (name == 'name') {
      this.title = newValue;
    }

    if (name == 'text') {
      this.description = newValue;
    }

    if (name == 'type') {
      if (newValue === 'success') {
        this.color = 'is-success';
      }

      if (newValue === 'error') {
        this.color = 'is-danger';
      }
    }

    this.innerHTML = `
      <div class="notification-wrapper">
        <div class="notification ${this.color}">
          <p><strong>${this.title}</strong></p>
          <p>${this.description}</p>
        </div>
      </div>
    `;
  }
}

customElements.define('softbrik-notification-message', NotificationMessage);
